import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  // Obtener el mes y año actual
  const currentMonth = new Date().getMonth() + 1; // Obtener el mes actual (1-indexado)
  const currentYear = new Date().getFullYear(); // Obtener el año actual

  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [month, setMonth] = useState(currentMonth); // Mes inicial: mes actual
  const [year, setYear] = useState(currentYear); // Año inicial: año actual

  useEffect(() => {
    //const url = `http://localhost:2020/api/tickets?place=2&road=1&year=${year}&month=${month}`;

    const url = `https://api.incatrailavailability.xyz/api/tickets?place=2&road=1&year=${year}&month=${month}`;

    const fetchTickets = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Error HTTP: ${response.status}`);
        }
        const data = await response.json();

        // Convertir el objeto de fechas a un arreglo de objetos
        const ticketsArray = Object.entries(data).map(([date, ticketsAvailable]) => ({
          day: date,
          ticketsAvailable,
        }));

        setTickets(ticketsArray);
        setLoading(false);
      } catch (error) {
        setError(`Error: ${error.message}`);
        setLoading(false);
      }
    };

    fetchTickets();
  }, [month, year]); // Dependencia de month y year

  // Mostrar mensajes de carga o error
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  // Obtener el primer día del mes (ajustado para que empiece el lunes)
  const firstDayOfMonth = new Date(year, month - 1, 1);
  const firstDayIndex = (firstDayOfMonth.getDay() === 0 ? 7 : firstDayOfMonth.getDay()) - 1; // Ajustamos para que 0 sea lunes
  
  // Crear un arreglo de días del mes seleccionado
  const daysInMonth = Array.from({ length: new Date(year, month, 0).getDate() }, (_, i) => i + 1);

  // Rellenar los días vacíos antes del primer día del mes
  const emptyDays = Array(firstDayIndex).fill(null); // Días vacíos antes del primer día del mes

  // Combinar los días vacíos con los días del mes
  const allDays = [...emptyDays, ...daysInMonth];

  return (
    <div className="App p-4 container relative max-w-6xl mx-auto">
    <img src='https://incredibleperutours.com/wp-content/uploads/cropped-cropped-cropped-logo-web_ipt-2048x289.webp' className='w-72 absolute top-16 xl:block hidden'></img>
      
    <div className='flex justify-center'>
      
      <h1 className='text-3xl mb-5 font-bold text-gray-800 uppercase'>Inca Trail Availability 2025</h1>
      
    </div>
      {/* Selectores para mes y año */}
      <div className="flex justify-center mb-4">
        <select
          value={month}
          onChange={(e) => setMonth(Number(e.target.value))}
          className="p-2 border rounded mr-2 text-xl px-3 border-gray-300"
        >
          {[...Array(12)].map((_, index) => {
            const monthValue = index + 1;
            if (year === currentYear && monthValue < currentMonth) return null; // No mostrar meses pasados en el año actual
            return (
              <option key={monthValue} value={monthValue}>
                {new Date(0, monthValue - 1).toLocaleString('en-US', { month: 'long' })}
              </option>
            );
          })}
        </select>

        <select
          value={year}
          onChange={(e) => {
            const selectedYear = Number(e.target.value);
            setYear(selectedYear);
            if (selectedYear !== currentYear) {
              setMonth(1); // Reiniciar a enero si el año cambia y es distinto del año actual
            } else {
              setMonth(currentMonth); // Si es el año actual, mantener el mes actual
            }
          }}
          className="p-2 border rounded text-xl px-3 border-gray-300"
        >
          <option value={currentYear}>{currentYear}</option>
          <option value={currentYear + 1}>{currentYear + 1}</option>
        </select>
      </div>

      <div className="grid grid-cols-7 gap-2">
        {/* Días de la semana */}
        {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((dayName) => (
          <div
            key={dayName}
            className="px-3 py-2 bg-white border border-blue-600 text-center rounded shadow-md text-sm xs:block md:text-base font-semibold"
          >
            <p className='md:block !mb-0 !text-blue-700 !text-center'>{dayName}</p>
          </div>
        ))}

        {/* Generar los días del calendario */}
        {allDays.map((day, index) => {
          if (!day) {
            // Si es un día vacío (null), mostrar una celda vacía
            return <div key={index} className=""></div>;
          }

          const date = `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
          const ticket = tickets.find((ticket) => ticket.day === date);

       
          const color = ticket 
        ? (ticket.ticketsAvailable >= 25 
            ? 'bg-blue-600' 
            : ticket.ticketsAvailable > 0 && ticket.ticketsAvailable < 20 
              ? 'bg-[#0099dd]' 
              : 'bg-gray-700') 
             : 'bg-gray-300';

          const colorheader = ticket ? (ticket.ticketsAvailable >= 25 ? 'border border-gray-300  shadow cursor-pointer  hover:shadow-lg' : ticket.ticketsAvailable > 0 ? 'transition-all shadow cursor-pointer hover:shadow-lg' : 'transition-all shadow opacity-30 hover:transform-none cursor-default hover:shadow-lg') : 'bg-gray-300';

          return (
            <article key={index} className={`group ${colorheader}`}>
              <header className={`flex justify-end font-semibold px-2 py-1  text-white ${color}`}>
                {day.toString().padStart(2, '0')}
              </header>
              <p className="m-auto text-center  py-2 xl:py-0 font-semibold  text-gray-600 text-sm xl:text-lg ">
                <div className='xl:bg-white  py-1.5 bg-white'>{ticket ? ticket.ticketsAvailable : '-'}</div>
                {ticket && ticket.ticketsAvailable > 0 ? (
                  <a
                    href={`https://localtrekkersperu.com/contact-us/`}
                    className=" text-sm font-normal text-gray-800 hidden xl:block hover:underline"
                  >
                    
                  </a>
                ) : (
                  <small className=" font-normal xl:block hidden"></small>
                )}
              </p>
            </article>
          );

        })}
      </div>
    </div>
  );
}

export default App;
